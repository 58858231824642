import * as $ from 'jquery';
(window as any).$ = $;
(window as any).jquery = $;
(window as any).jQuery = $;

export default class ShopController {
  private readonly products = document.querySelectorAll('.productList--labor .productList__productsRow');
  private readonly shopMenuButton = document.querySelector('.js-labor-shop-menu');
  private readonly pricesEndpoint = window.location.origin + window.location.pathname + '/api/v1/shop/product/list?tx_thgshop_shopapiproducts[action]=price&tx_thgshop_shopapiproducts[shoptype]=labor&tx_thgshop_shopapiproducts[id]=';
  private readonly searchResultsEndpoint = window.location.origin + window.location.pathname + '/api/v1/shop/product/list?tx_thgshop_shopapiproducts[action]=searchResults&tx_thgshop_shopapiproducts[shoptype]=thgeyer&tx_thgshop_shopapiproducts[searchWord]=';
  private readonly cartEndpoint = window.location.origin + window.location.pathname + '/api/v1/shop/cart/info';
  private readonly wishlistEndpoint = window.location.origin + '/de/ingredients/shop/note/count';
  private readonly userInfo = window.location.origin + window.location.pathname + '/api/v1/shop/user/show';
  private readonly shopMenuEndpoint = window.location.origin + '/lab/shop/widgets/accountMenu.action';
  private headerCart = document.querySelectorAll('.header-cart');
  private headerWishlist = document.querySelectorAll('.js-wishlist');
  private stickyHeader = document.querySelector('.js-sticky-header');
  private shopLab = document.querySelector('.header-main.shopContext__labor');
  private cartPushButtons = document.querySelectorAll('.productList__productsItem .btn-buy');
  private solrSearchInput = (document.querySelector('.tx-solr-input') as HTMLInputElement);

  constructor() {
    if (this.shopMenuButton) {
      this.fetchShopMenu();
    }
    const body = document.querySelector('body');
    if (!body) {
      alert('No body Element!');
    }
    if (this.solrSearchInput?.value) {
      this.getSearchResults(this.solrSearchInput?.value);
    }
    const accountWidget = document.querySelector('#accountWidget');
    if (!accountWidget) {
      return;
    }
    this.addBodyClass(body);
    if (this.headerCart) {
      this.headerCart.forEach((cartItem) => {
        this.updateCartItems(cartItem);
      });
    }
    this.cartPushButtons.forEach((button) => {
      button.addEventListener('click', async (event) => {
        event.preventDefault();
        this.pushToCart(button, event);
      });
    });
    this.products.forEach((product) => {
      this.getProductPrice(product);
    });
  }

  manageUserText() {
    const loggedIn = document.getElementsByClassName('isLoggedIn').length;
    if (loggedIn) {
      const accountMenu = document.querySelector('.account-menu');
      const accountString = (accountMenu?.querySelector('.account-menu-btn') as HTMLElement).dataset.accountstring;
      if (accountMenu) {
        const login = document.querySelector('.account-menu .login');
        const icon = accountMenu?.querySelector('.material-icons-sharp');
        if (login && icon) {
          const replaceString = accountString ?? 'Shop';
          accountMenu?.querySelector('.account-menu-btn')?.setAttribute('aria-label', replaceString);
          icon.innerHTML = 'local_mall';
          login.innerHTML = replaceString;
        }
      }
    }
  }

  async addBodyClass(body) {
    const shopLab = document.querySelector('.header-main.shopContext__labor');
    let isLoggedIn = false;
    if (shopLab) {
      isLoggedIn = typeof this.getCookieByName('SHOP_NAME') === 'string' ? atob(this.getCookieByName('SHOP_NAME')) !== 'Gast Kunde' : false;
    } else {
      const response = await fetch(this.userInfo);
      if (!response.ok) {
        return;
      }
      const bodyValue = await response.json();
      isLoggedIn = bodyValue.isLoggedIn;
    }

    this.updateStickyHeader(isLoggedIn);

    if (isLoggedIn) {
      body.classList.add('isLoggedIn');
      this.manageUserText();
    }
    if (this.headerWishlist) {
      this.headerWishlist.forEach((wishlistItem) => {
        this.updateWishlistItems(wishlistItem);
      });
    }
  }

  async fetchShopMenu() {
    if (!this.shopMenuButton) {
      return;
    }

    let shopNameCookie = this.getCookieByName('SHOP_NAME');
    let isLoggedIn = typeof shopNameCookie === 'string' ? atob(shopNameCookie) !== 'Gast Kunde' : false;
    if (!isLoggedIn) {
      return;
    }

    const buttonLink = this.shopMenuButton.querySelector('#accountWidget');
    buttonLink?.addEventListener('click', (event) => {
      event.preventDefault();
    });

    const response = await fetch(this.shopMenuEndpoint);
    if (!response.ok) {
      return;
    }
    let body = await response.text();
    if (!body) {
      return;
    }

    let css = body.substring(7, body.indexOf('</style'));
    let style = document.createElement('style');
    document.head.appendChild(style);
    style.appendChild(document.createTextNode(css));

    let html = body.substring(body.indexOf('<div'), body.indexOf('<script type="text/javascript">'));
    const htmlParsed = new DOMParser().parseFromString(html, 'text/html');
    const dropdownMenu = htmlParsed.querySelector('#account_menu');
    if (!dropdownMenu) {
      return;
    }
    dropdownMenu.classList.add('hide');
    this.shopMenuButton?.appendChild(dropdownMenu);

    let javascript = body.substring(body.indexOf('<script type="text/javascript">') + '<script type="text/javascript">'.length, body.indexOf('</script'));
    const scriptTag = document.createElement('script');
    scriptTag.type = 'text/javascript';
    scriptTag.innerHTML = javascript;
    this.shopMenuButton?.appendChild(scriptTag);

    buttonLink?.addEventListener('click', async () => {
      dropdownMenu.classList.toggle('hide');
    });

    document.addEventListener('click', (e) => {
      if (!(<Element>e?.target).closest('.js-labor-shop-menu')) {
        dropdownMenu.classList.add('hide');
      }
    });
  }

  async getSearchResults(searchWord) {
    const response = await fetch(this.searchResultsEndpoint + searchWord);
    const ingredientsShopButton = (document.querySelector('.summary__shops .ingredients') as HTMLElement);
    if (!response.ok) {
      return;
    }
    const body = await response.json();
    const prependString = body ? '' : ingredientsShopButton.dataset.nosearchresults;
    if (!body) {
      ingredientsShopButton.classList.add('disabled');
    }
    ingredientsShopButton.innerText = prependString?.concat(ingredientsShopButton?.innerText) ?? 'No ';
  }

  async getProductPrice(product) {
    const dataset = (product as HTMLElement).dataset;
    const productId = dataset.id;
    if (productId) {
      const response = await fetch(this.pricesEndpoint + productId);
      if (!response.ok) {
        return;
      }
      const body = await response.json();
      product.querySelector('.productList__productsItemPrice').innerText = body.price;
    }
  }

  async updateCartItems(headerCart) {
    let itemCount = 0;
    if (this.shopLab) {
      itemCount = typeof this.getCookieByName('SHOP_CART_POS') === 'string' ? Number(atob(this.getCookieByName('SHOP_CART_POS'))) : 0;
    } else {
      const response = await fetch(this.cartEndpoint);
      if (!response.ok) {
        return;
      }
      const body = await response.json();
      itemCount = body.itemCount;
    }

    headerCart.querySelector('.header-cart-badge').innerText = itemCount;
  }

  async updateWishlistItems(headerWishlist) {
    const loggedIn = document.getElementsByClassName('isLoggedIn').length;
    if (!loggedIn) {
      headerWishlist.classList.add('hide');
      return;
    }
    let itemCount;
    if (this.shopLab) {
      itemCount = typeof this.getCookieByName('SHOP_WISHLIST_POS') === 'string' ? Number(atob(this.getCookieByName('SHOP_WISHLIST_POS'))) : 0;
    } else {
      const response = await fetch(this.wishlistEndpoint);
      if (!response.ok) {
        return;
      }
      itemCount = await response.text();
    }

    headerWishlist.querySelector('.header-wishlist-badge').innerText = itemCount;
  }

  updateStickyHeader(isLoggedIn) {
    if (!this.stickyHeader) {
      return;
    }
    const shopIng = document.querySelector('.header-main.shopContext__thgeyer');

    if (!isLoggedIn && shopIng) {
      this.stickyHeader.classList.add('hide');
      return;
    }
  }

  async pushToCart(button, event) {
    let endpoint = button.dataset.action;
    const selectedAmount = button.closest('.productList__productsRow').querySelector('input.amount').value;
    if (selectedAmount > 1) {
      endpoint = endpoint + '&quantity=' + selectedAmount;
    }
    const response = await fetch(endpoint);
    if (!response.ok) {
      return;
    }
    const body = await response.json();
    if (body) {
      this.updateCartItems(this.headerCart);
      this.updateWishlistItems(this.headerWishlist);
      button.innerHTML = '<span class="material-icons-sharp">check</span>' + button.dataset.success;
      button.setAttribute('disabled', 'true');
    } else {
      alert('Something went wrong, please reload the page.');
    }
  }

  getCookieByName(cookieName) {
    let cookie = {};
    document.cookie.split(';').forEach(function (e) {
      let [key, value] = e.split('=');
      cookie[key.trim()] = value;
    });
    return cookie[cookieName];
  }
}
